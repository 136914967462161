<template>
    <div>
        <div class="row section-header" >
            <div class="col-md-3 section-title">
                <h3>Home</h3>
            </div>

            <div class="col-md-6">

            </div>
            <div class="col-md-3 text-right" style="">
            </div>
        </div>
        <div>

            <br>
            <h3>Benvenuto nel gestionale dedicato alle autoscuole.</h3>
            <p>Clicca su uno dei link nel menu.</p>
        </div>
    </div>

</template>

<script>

import {ParseUrl} from "@/helpers/ParseUrl";
import ProductLicenseDataService from "@/components/product_license/ProductLicenseDataService";
import EduLabDataService from "@/components/product_license_template/EduLabDataService";

export default {
    name: "Home",
    mounted() {
        const parsedUrl = ParseUrl();
        if (parsedUrl.queryParams.has('edulabProductId')) {
            // check if user has the product license active
            ProductLicenseDataService.hasActiveLicenseByProfile(parsedUrl.queryParams.get('edulabProductId'))
                .then(response => {
                   if (response.data.length > 0) {
                       let schoolId = response.data[0].schoolId;
                       // license is active
                       // redirect to edulab resource
                       location.href = EduLabDataService.edulabBaseUrl + parsedUrl.query + '&schoolId='+schoolId + '&token='+this.$store.state.token;
                   } else {
                       // license not registered or expired
                       this.$router.push('/product-denied');
                   }
                })
                .catch(e => {
                    this.errorMsg = e.response.data.message;
                    console.log(e);
                });
        }
    }
}
</script>

<style scoped>
</style>
